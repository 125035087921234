import React, { useState } from 'react';
import { Form, Button, InputGroup, ProgressBar } from 'react-bootstrap';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import zxcvbn from 'zxcvbn';
import './RegisterForm.css';

function RegisterForm() {
    const [formData, setFormData] = useState({
        email: '',
        companyName: '',
        password: '',
    });

    const [errors, setErrors] = useState([]);
    const [showErrorModal, setShowErrorModal] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value,
        });
    };

    const validateForm = () => {
        const newErrors = [];
        if (!formData.email) newErrors.push({ message: "An <b>Email</b> address is required to create your account.", boldFields: ["Email"] });
        if (!formData.companyName) newErrors.push({ message: "A <b>Company Name</b> is required to create your account.", boldFields: ["Company Name"] });
        if (!formData.password) newErrors.push({ message: "A <b>Password</b> is required to create your account.", boldFields: ["Password"] });

        // Password security requirements
        if (formData.password.length < 8 || !/[A-Za-z]/.test(formData.password) || !/[0-9]/.test(formData.password)) {
            newErrors.push({ message: "Please ensure your password is at least 8 characters long and contains a mix of letters and numbers", boldFields: [] });
        }

        return newErrors;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const validationErrors = validateForm();
        if (validationErrors.length > 0) {
            setErrors(validationErrors);
            setShowErrorModal(true);
            return;
        }

        try {
            const response = await fetch('https://api.foveaai.com/evaluate/web/user/register', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    email: formData.email,
                    company_name: formData.companyName,
                    password: formData.password,
                }),
            });

            if (response.ok) {
                setSuccessMessage('Your account has been created. An email has been sent to your email address. Please click the link in the email to verify your account.');
            } else {
                const errorData = await response.json();
                setErrors([{ message: errorData.message || "Failed to register user — please try again later", boldFields: [] }]);
                setShowErrorModal(true);
            }
        } catch (error) {
            console.error("Error registering user:", error);
            setErrors([{ message: "Error registering user", boldFields: [] }]);
            setShowErrorModal(true);
        }
    };

    const getPasswordStrength = (password) => {
        const result = zxcvbn(password);
        return result.score;
    };

    const isPasswordAcceptable = (password) => {
        return password.length >= 8 && /[A-Za-z]/.test(password) && /[0-9]/.test(password);
    };

    const getPasswordStrengthLabel = (password) => {
        if (isPasswordAcceptable(password)) {
            return { label: 'Accepted', color: 'success' };
        } else {
            return { label: 'Weak', color: 'danger' };
        }
    };

    const passwordStrength = getPasswordStrength(formData.password);
    const { label: passwordStrengthLabel, color: passwordStrengthColor } = getPasswordStrengthLabel(formData.password);

    return (
        <div className="register-form-container">
            {successMessage ? (
                <div className="success-message">
                    <p>{successMessage}</p>
                </div>
            ) : (
                <Form className="register-form" onSubmit={handleSubmit}>
                    <Form.Group className="form-group">
                        <Form.Label htmlFor="email">Email Address</Form.Label>
                        <Form.Control type="email" className="form-control" id="email" name="email" value={formData.email} onChange={handleChange} required />
                    </Form.Group>
                    <Form.Group className="form-group">
                        <Form.Label htmlFor="companyName">Company Name</Form.Label>
                        <Form.Control type="text" className="form-control" id="companyName" name="companyName" value={formData.companyName} onChange={handleChange} required />
                    </Form.Group>
                    <Form.Group className="form-group">
                        <Form.Label htmlFor="password">Password</Form.Label>
                        <InputGroup>
                            <Form.Control
                                type={showPassword ? "text" : "password"}
                                className="form-control"
                                id="password"
                                name="password"
                                value={formData.password}
                                onChange={handleChange}
                                required
                            />
                            <Button variant="outline-secondary" onClick={() => setShowPassword(!showPassword)}>
                                {showPassword ? <FaEyeSlash /> : <FaEye />}
                            </Button>
                        </InputGroup>
                    </Form.Group>
                    <Form.Group className="form-group">
                        <Form.Label></Form.Label>
                        <div className="password-strength-container">
                            <ProgressBar
                                now={(passwordStrength + 1) * 20}
                                label={passwordStrengthLabel}
                                variant={passwordStrengthColor}
                                className="password-strength-bar"
                            />
                        </div>
                    </Form.Group>
                    <Button type="submit" className="btn btn-primary">Create Account</Button>
                </Form>
            )}
            {showErrorModal && (
                <div className="error-modal">
                    <div className="error-modal-content">
                        <h3>Oops! Please check the following:</h3>
                        <ul>
                            {errors.map((error, index) => (
                                <li key={index}>
                                    {error.message.split(" ").map((word, i) => (
                                        error.boldFields.includes(word) ? <b key={i}>{word} </b> : `${word} `
                                    ))}
                                </li>
                            ))}
                        </ul>
                        <Button onClick={() => setShowErrorModal(false)} className="btn btn-danger">Close</Button>
                    </div>
                </div>
            )}
        </div>
    );
}

export default RegisterForm;