import "./AnalysisDetail.css";
import { GetColorForRating } from "../../utils/FormattingUtils";

import AnalysisDetailForm from "../AnalysisDetailForm/AnalysisDetailForm";
import PDFDownload from "../PDFDownload/PDFDownload";
import LoadingOverlay from "../LoadingOverlay/LoadingOverlay";
import CandidateRatingChart from "../CandidateRatingChart/CandidateRatingChart";

import React, { useState, useEffect } from "react";
import axios from "axios";
import { useCookies } from "react-cookie";
import { Button } from "reactstrap";
import { Modal } from "react-bootstrap";
import { TextWithNewLines } from "../../utils/FormattingUtils";
import { useNavigate } from "react-router-dom";

const AnalysisDetail = ({
    analysisId,
    analysis_object = null,
    closeModal = null,
    refreshData = null,
}) => {
    const [analysis, setAnalysis] = useState(null);
    const [refresh, setRefresh] = useState(Math.random());
    const [showEditModal, setShowEditModal] = useState(false);
    const [showPDFModal, setShowPDFModal] = useState(false);
    const [cookies] = useCookies(["AuthorizationToken"]);
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        // Declare an async function to fetch the analysis details inside the useEffect hook
        const fetchAnalysisDetails = async () => {
            setIsLoading(true);
            try {
                const analysis_response = await axios.get(
                    `https://api.foveaai.com/evaluate/web/analyses/${analysisId}`,
                    {
                        headers: { Authorization: cookies.AuthorizationToken },
                    }
                );

                const analysis_data =
                    typeof analysis_response.data === "string"
                        ? JSON.parse(analysis_response.data)
                        : analysis_response.data;

                setAnalysis(analysis_data);
                console.log("Analysis data:", analysis_data);
            } catch (error) {
                console.error("Error fetching analysis details:", error);
            } finally {
                setIsLoading(false);
            }
        };

        // Fetch the analysis details if the analysis object is not provided
        if (analysis_object == null) {
            fetchAnalysisDetails();
        }

        // Otherwise, use the analysis object
        else {
            setAnalysis(analysis_object);
        }

        console.log("Analysis object:", analysis_object);
    }, [analysisId, analysis_object, cookies.AuthorizationToken, refresh]);

    const handleEditModalClose = () => {
        setShowEditModal(false);
    };

    const handlePDFModalClose = () => {
        setShowPDFModal(false);
    };

    const handleRefreshData = () => {
        setRefresh(Math.random());
    };

    const handleDeleteAnalysis = async () => {
        setIsLoading(true);
        try {
            // Display a confirmation dialog before deleting the job
            if (
                !window.confirm(
                    "Are you sure you want to delete this evaluation? This action cannot be undone."
                )
            ) {
                return;
            }

            const response = await axios.delete(
                `https://api.foveaai.com/evaluate/web/analyses/${analysis.analysis.id}`,
                {
                    headers: { Authorization: cookies.AuthorizationToken },
                }
            );
            console.log("Completed analysis delete:", response.data);

            // Redirect to the job page if we are not there
            navigate(`/job/${analysis.analysis.job_id}`);

            // Refresh the data if the parent component provides a refreshData function
            if (refreshData) refreshData();

            // Close the modal if the parent component provides a closeModal function
            if (closeModal) closeModal();
        } catch (error) {
            console.error("Error deleting analysis:", error);

            // Alert the user that there was an issue deleting the evaluation
            alert("Error deleting the evaluation. Please try again later.");

            // Redirect to the job page if we are not there
            navigate(`/job/${analysis.analysis.job_id}`);

            // Close the modal if the parent component provides a closeModal function
            if (closeModal) closeModal();
        } finally {
            // Remove the dim from the component
            setIsLoading(false);
        }
    };

    return (
        <div className="analysis-detail">
            {isLoading && <LoadingOverlay />}
            <Modal
                show={showEditModal}
                onHide={() => setShowEditModal(false)}
                size="lg"
                aria-labelledby="contained-model-title-vcenter"
                centered
                dialogClassName="analysis-detail-modal-dialog-custom"
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-model-title-vcenter">
                        Edit Evaluation
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="analysis-detail-modal-body-custom">
                    <AnalysisDetailForm
                        analysisId={analysisId}
                        closeModal={handleEditModalClose}
                        refreshData={handleRefreshData}
                    />
                </Modal.Body>
            </Modal>

            <Modal
                show={showPDFModal}
                onHide={() => setShowPDFModal(false)}
                size="lg"
                aria-labelledby="contained-model-title-vcenter"
                centered
                dialogClassName="analysis-detail-modal-dialog-custom"
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-model-title-vcenter">
                        Items Included in Candidate Profile
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="analysis-detail-modal-body-custom">
                    <PDFDownload
                        analysisId={analysisId}
                        closeModal={handlePDFModalClose}
                    />
                </Modal.Body>
            </Modal>

            <div className="buttons-container">
                <Button
                    color="dark"
                    className="analysis-button"
                    onClick={() => {navigate(`/job/${analysis.analysis.job_id}`);}}
                >
                    Back
                </Button>
                <Button
                    color="secondary"
                    className="analysis-button"
                    onClick={() => setShowEditModal(true)}
                >
                    Edit
                </Button>
                <Button
                    color="danger"
                    className="analysis-button"
                    onClick={handleDeleteAnalysis}
                >
                    Delete Candidate
                </Button>
                <Button
                    color="primary"
                    className="analysis-button"
                    style={{ marginTop: 0 }}
                    onClick={() => setShowPDFModal(true)}
                >
                    Download
                </Button>
            </div>
            <div className="analysis-detail-grid">
                <div className="analysis-detail-label">
                    <div className="rating-label">Rating:</div>
                    {analysis && (
                        <div className="rating-circle-detail-container">
                            <div 
                                className="rating-circle-detail" 
                                style={{ backgroundColor: GetColorForRating(analysis.analysis) }}
                            >
                                {analysis.analysis.job_fit_rating_normalized}
                            </div>
                        </div>
                    )}
                </div>
                <div className="analysis-detail-value job-fit-rating-chart">
                    <CandidateRatingChart
                        minYears={
                            analysis
                                ? analysis.analysis
                                      .job_fit_rating_calculation_min_years
                                : 0
                        }
                        maxYears={
                            analysis
                                ? analysis.analysis
                                      .job_fit_rating_calculation_max_years
                                : 0
                        }
                        valueToGraph={
                            analysis
                                ? analysis.analysis
                                      .job_fit_rating_calculation_total
                                : 0
                        }
                    />
                </div>
                <div className="analysis-detail-label">Candidate Name:</div>
                <div className="analysis-detail-value">
                    {analysis ? analysis.first_name : ""}{" "}
                    {analysis ? analysis.last_name : ""}
                </div>
                <div className="analysis-detail-label">Primary Job Title:</div>
                <div className="analysis-detail-value">
                    {analysis ? analysis.primary_job_title : ""}
                </div>
                <div className="analysis-detail-label">Location:</div>
                <div className="analysis-detail-value">
                    {analysis && (
                        <>
                            {analysis.locality && analysis.administrative_area
                                ? `${analysis.locality}, ${analysis.administrative_area} `
                                : `${analysis.locality || ""}${
                                      analysis.administrative_area || ""
                                  } `}
                            {analysis.postal_code || ""}
                        </>
                    )}
                </div>
                <div className="analysis-detail-label">Commute:</div>
                <div className="analysis-detail-value">
                    {analysis &&
                    analysis.analysis.driving_duration &&
                    analysis.analysis.driving_distance ? (
                        analysis.analysis.driving_duration !== 0 &&
                        analysis.analysis.driving_distance !== 0 ? (
                            <>
                                {(
                                    analysis.analysis.driving_distance / 1609.34
                                ).toFixed(0)}{" "}
                                miles |{" "}
                                {Math.floor(
                                    analysis.analysis.driving_duration / 3600
                                ) > 0
                                    ? `${Math.floor(
                                          analysis.analysis.driving_duration /
                                              3600
                                      )} hours `
                                    : ""}
                                {Math.round(
                                    (analysis.analysis.driving_duration %
                                        3600) /
                                        60
                                )}{" "}
                                minutes
                            </>
                        ) : (
                            "Not Calculated"
                        )
                    ) : (
                        "Not Calculated"
                    )}
                </div>
                <div className="analysis-detail-label">Evaluated From:</div>
                <div className="analysis-detail-value">
                    {analysis && analysis.source_url ? (
                        <a href={analysis.source_url} target="_blank" rel="noopener noreferrer">
                            {new URL(analysis.source_url).hostname}
                        </a>
                    ) : (
                        "Internal Source"
                    )}
                </div>
                <div className="analysis-detail-label">Email:</div>
                <div className="analysis-detail-value">
                    <a href={`mailto:${analysis ? analysis.email : ""}`}>
                        {analysis ? analysis.email : "No email provided"}
                    </a>
                </div>
                <div className="analysis-detail-label">Phone:</div>
                <div className="analysis-detail-value">
                    {analysis ? analysis.phone : ""}
                </div>
                <div className="analysis-detail-label">Candidate Summary:</div>
                <div className="analysis-detail-value">
                    {analysis ? (
                        <TextWithNewLines
                            text={analysis.analysis.candidate_summary}
                        />
                    ) : (
                        ""
                    )}
                </div>
                <div className="analysis-detail-label">Job Fit Narrative:</div>
                <div className="analysis-detail-value">
                    {analysis ? (
                        <TextWithNewLines
                            text={analysis.analysis.job_fit_narrative}
                        />
                    ) : (
                        ""
                    )}
                </div>
                <div className="analysis-detail-label">Recruiter Notes:</div>
                <div className="analysis-detail-value">
                    {analysis ? (
                        <TextWithNewLines text={analysis.analysis.notes} />
                    ) : (
                        ""
                    )}
                </div>
                <div className="analysis-detail-label">
                    Interview Questions:
                </div>
                <div className="analysis-detail-value">
                    {analysis ? (
                        <TextWithNewLines
                            text={analysis.analysis.interview_questions}
                        />
                    ) : (
                        ""
                    )}
                </div>
                <div className="analysis-detail-label">Skill Set:</div>
                <div className="analysis-detail-value">
                    {analysis ? (
                        <TextWithNewLines text={analysis.skill_set} />
                    ) : (
                        ""
                    )}
                </div>
                <div className="analysis-detail-label">Experience:</div>
                <div className="analysis-detail-value">
                    {analysis &&
                    analysis.experiences &&
                    analysis.experiences.length > 0 ? (
                        analysis.experiences.map((exp, index) => (
                            <div key={index} className="experience-item">
                                <div className="experience-header">
                                    {exp.title}
                                </div>
                                <div>
                                    {exp.company ? (
                                        <TextWithNewLines text={exp.company} />
                                    ) : (
                                        ""
                                    )}
                                </div>
                                <div className="experience-date">
                                    {exp.from_date} to{" "}
                                    {exp.to_date || "Present"}
                                </div>
                                <div className="experience-summary">
                                    {exp.summary ? (
                                        <TextWithNewLines text={exp.summary} />
                                    ) : (
                                        ""
                                    )}
                                </div>
                            </div>
                        ))
                    ) : (
                        <div>No experience provided</div>
                    )}
                </div>
                <div className="analysis-detail-label">Education:</div>
                <div className="analysis-detail-value">
                    {analysis &&
                    analysis.educations &&
                    analysis.educations.length > 0 ? (
                        analysis.educations.map((edu, index) => (
                            <div key={index} className="education-item">
                                <div className="education-header">
                                    {edu.school ? (
                                        <TextWithNewLines text={edu.school} />
                                    ) : (
                                        ""
                                    )}
                                </div>
                                <div>
                                    {edu.degree ? (
                                        <TextWithNewLines text={edu.degree} />
                                    ) : (
                                        ""
                                    )}
                                </div>
                                <div>
                                    {edu.major ? (
                                        <TextWithNewLines text={edu.major} />
                                    ) : (
                                        ""
                                    )}
                                </div>
                                <div className="education-date">
                                    {edu.from_date} to{" "}
                                    {edu.to_date || "Present"}
                                </div>
                            </div>
                        ))
                    ) : (
                        <div>No education provided</div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default AnalysisDetail;
