import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import { Form, Button } from 'react-bootstrap';
import './VerifyComponent.css';

const VerifyComponent = () => {
    const { token } = useParams();
    const navigate = useNavigate();
    const [message, setMessage] = useState('');
    const [status, setStatus] = useState('');
    const [email, setEmail] = useState('');
    const [countdown, setCountdown] = useState(5);

    useEffect(() => {
        const verifyUser = async () => {
            try {
                const response = await axios.get(`https://api.foveaai.com/evaluate/web/user/verify/${token}`);
                console.log("Verify response", response.data);
                if (response.data.status === 'account_verified' || response.data.status === 'account_already_verified') {
                    setMessage(response.data.message);
                    setStatus(response.data.status);
                    const timer = setInterval(() => {
                        setCountdown(prevCountdown => {
                            if (prevCountdown === 1) {
                                clearInterval(timer);
                                navigate('/login');
                            }
                            return prevCountdown - 1;
                        });
                    }, 1000);
                } else if (response.data.status === 'password_reset_required') {
                    setMessage(response.data.message);
                    setStatus(response.data.status);
                } else if (response.data.status === 'verification_link_expired') {
                    setMessage(response.data.message);
                    setStatus(response.data.status);
                }
            } catch (error) {
                setMessage('The email verification link is invalid or has expired.');
                setStatus('verification_link_expired');
            }
        };

        verifyUser();
    }, [token, navigate]);

    const handleResendVerification = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post('https://api.foveaai.com/evaluate/web/user/resend_verification', { email });
            console.log("Resend response", response.data);
            if (response.data.status === 'account_already_verified') {
                setMessage(response.data.message);
                setStatus(response.data.status);
                const timer = setInterval(() => {
                    setCountdown(prevCountdown => {
                        if (prevCountdown === 1) {
                            clearInterval(timer);
                            navigate('/login');
                        }
                        return prevCountdown - 1;
                    });
                }, 1000);
            } else {
                setMessage(response.data.message);
            }
        } catch (error) {
            if (error.response && error.response.data) {
                if (error.response.data.status === 'account_already_verified') {
                    setMessage(error.response.data.message);
                    setStatus(error.response.data.status);
                    const timer = setInterval(() => {
                        setCountdown(prevCountdown => {
                            if (prevCountdown === 1) {
                                clearInterval(timer);
                                navigate('/login');
                            }
                            return prevCountdown - 1;
                        });
                    }, 1000);
                } else {
                    setMessage(error.response.data.message);
                }
            } else {
                setMessage('An error occurred while resending the verification email.');
            }
        }
    };

    return (
        <div className="verify-container">
            {status === 'verification_link_expired' ? (
                <div className="verify-form-container">
                    <Form className="verify-form" onSubmit={handleResendVerification}>
                        {message && <p className="verify-message">{message}</p>}
                        <Form.Group className="form-group">
                            <Form.Label htmlFor="email">Email Address</Form.Label>
                            <Form.Control
                                type="email"
                                className="form-control"
                                id="email"
                                name="email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                required
                            />
                        </Form.Group>
                        <Button type="submit" className="btn btn-primary">Resend Verification Email</Button>
                    </Form>
                </div>
            ) : (
                <div className="verify-form-container">
                    <div className="verify-form">
                        {message && <p className="verify-message">{message}</p>}
                        {(status === 'account_verified' || status === 'account_already_verified') && (
                            <div>
                                <p>Redirecting in {countdown} seconds...</p>
                                <Button onClick={() => navigate('/login')}>Go to Login Page</Button>
                            </div>
                        )}
                    </div>
                </div>
            )}
        </div>
    );
};

export default VerifyComponent;